import { Select } from "@chakra-ui/react";
import { useState } from "react";
import { ClientSchemaFieldDto } from "../../../types/organization";
import { theme } from "../../../constants";

interface DropdownFieldProps {
  field: ClientSchemaFieldDto;
  initialValue?: string,
  handleInputChange: (key: string, value: string) => void;
}

export const DropdownField = ({
  field,
  initialValue,
  handleInputChange,
}: DropdownFieldProps) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(initialValue);

  return (
    <Select 
      value={selectedValue}
      onChange={(e) => {
        setSelectedValue(e.target.value);
        handleInputChange(field.key, e.target.value);
      }}
      fontSize={{ base: "md", "2xl": "lg" }} 
      fontWeight="medium" 
      color={`${theme}.900`} 
      variant={"flushed"}
    >
      {field.dropdownOptions!.map((value, index) => 
        <option key={index} value={value}>{value}</option>
      )}
    </Select>
  );
};
