import { BoxProps, FormControl, FormLabel } from "@chakra-ui/react";
import { ClientSchemaFieldDto, ClientSchemaFieldType } from "../../../types/organization";
import { TextField } from "./TextField";
import NumberField from "./NumberField";
import { CheckboxField } from "./CheckboxField";
import { theme } from "../../../constants";
import { DropdownField } from "./DropdownField";

interface CustomClientFieldProps extends BoxProps {
  field: ClientSchemaFieldDto;
  initialValue?: string,
  handleInputChange: (key: string, value: string) => void;
}

const CustomClientField = ({
  field,
  initialValue,
  handleInputChange,
  ...rest
}: CustomClientFieldProps) => {
  const fieldTypeMap: Record<string, React.ReactNode> = {
    [ClientSchemaFieldType.TEXT]: (
      <TextField field={field} initialValue={initialValue} handleInputChange={handleInputChange} />
    ),
    [ClientSchemaFieldType.NUMBER]: (
      <NumberField field={field} initialValue={initialValue} handleInputChange={handleInputChange} />
    ),
    [ClientSchemaFieldType.CHECKBOX]: (
      <CheckboxField field={field} initialValue={initialValue} handleInputChange={handleInputChange} />
    ),
    [ClientSchemaFieldType.DROPDOWN]: (
      <DropdownField field={field} initialValue={initialValue} handleInputChange={handleInputChange} />
    ),
  };

  return (
    <FormControl {...rest}>
      <FormLabel fontFamily="heading" fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`}>
        {field.label}
      </FormLabel>
      {fieldTypeMap[field.responseType]}
    </FormControl>
  );
};

export default CustomClientField;
