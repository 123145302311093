import {
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormTypeField } from "../../types/form";
import { checkNumberFieldInvalid } from "../../utils/validators";

interface NumberFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
}

export const NumberFormField = ({
  field,
  handleInputChange,
}: NumberFormFieldProps) => {
  const [value, setValue] = useState<string>("");
  return (
    <>
      {(field.numberOptions?.max || field.numberOptions?.min) && (
        <FormHelperText>
          (
          {field.numberOptions?.min !== undefined &&
            `Min: ${field.numberOptions.min}, `}
          {field.numberOptions?.max !== undefined &&
            `Max: ${field.numberOptions.max}`}
          )
        </FormHelperText>
      )}
      <NumberInput
        isRequired={field.required}
        isInvalid={checkNumberFieldInvalid(field, value)}
        onChange={(valueString) => {
          setValue(valueString);
          handleInputChange(field.key, valueString);
        }}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
};

export default NumberFormField;
