import {
  Box,
  FormControl,
  HStack,
  Input,
  Select,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import RadioOptions from "./RadioOptions";
import {
  OptionsLayout,
  FormTypeField,
} from "../../../types/form";
import { theme } from "../../../constants";

interface RadioFlowFieldProps {
  field: FormTypeField;
  updateField?: (field: FormTypeField) => void;
}

const RadioFlowField = ({ field, updateField }: RadioFlowFieldProps) => {
  const parseOptions = (options: string) => {
    const optionsArray = options
      .split(";")
      .map((option) => option.trim());
    if (options === "" ||optionsArray.length === 0) {
      return {};
    }

    const optionsObject = optionsArray.reduce((acc, option) => {
      acc[option] = option;
      return acc;
    }, {} as Record<string, string>);
    return optionsObject;
  };
  
  return (
    <VStack w="full" align={"left"}>
      <FormControl
        isRequired
        alignItems={"center"}
      >
        <HStack w={"full"}>
          <Input
            size={{ base: "sm", "2xl": "md" }}
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="bold"
            color={`${theme}.800`}
            value={Object.keys(field.options || {}).join(";")}
            onChange={(e) => {
              const newField: FormTypeField = {
                ...field,
                options: parseOptions(e.target.value),
              };
              updateField && updateField(newField);
            }}
            placeholder="Enter options separated by ;"
          />
          <Tooltip shouldWrapChildren label="Radio options for the question.">
            <AiOutlineInfoCircle />
          </Tooltip>
        </HStack>
      </FormControl>
      <HStack>
        <Select
          size={{ base: "sm", "2xl": "md" }}
          fontSize={{ base: "md", "2xl": "lg" }}
          fontWeight="bold"
          color={`${theme}.800`}
          w={"30%"}
          value={field.layout}
          placeholder="Layout"
          onChange={(e) => {
            const newField: FormTypeField = {
              ...field,
              layout: e.target.value as OptionsLayout,
            };
            updateField && updateField(newField);
          }}
        >
          <option value={OptionsLayout.VERTICAL}>Vertical</option>
          <option value={OptionsLayout.HORIZONTAL}>Horizontal</option>
        </Select>
        <Input
          size={{ base: "sm", "2xl": "md" }}
          fontSize={{ base: "md", "2xl": "lg" }}
          fontWeight="bold"
          color={`${theme}.800`}
          w="35%"
          onChange={(e) => {
            const newField: FormTypeField = {
              ...field,
              rangeLabels: {
                ...field.rangeLabels,
                low: e.target.value,
              },
            };
            updateField && updateField(newField);
          }}
          placeholder="Min Label"
        />
        <Input
          size={{ base: "sm", "2xl": "md" }}
          fontSize={{ base: "md", "2xl": "lg" }}
          fontWeight="bold"
          color={`${theme}.800`}
          w="35%"
          onChange={(e) => {
            const newField: FormTypeField = {
              ...field,
              rangeLabels: {
                ...field.rangeLabels,
                high: e.target.value,
              },
            };

            updateField && updateField(newField);
          }}
          placeholder="Max Label"
        />
      </HStack>
      <Box>
        {field.options && (
          <RadioOptions
            rangeLabels={field.rangeLabels}
            layout={field.layout}
            options={field.options}
          />
        )}
      </Box>
    </VStack>
  );
};

export default RadioFlowField;
