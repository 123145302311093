import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  VStack,
  useToast,
  InputGroup,
  InputRightElement,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputLeftElement,
  Text,
  HStack,
  Tag,
} from "@chakra-ui/react";
import { CloseIcon, ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import {
  bulkCreatePatients,
  createPatient,
  deletePatient,
  getPatientList,
  updatePatient,
} from "../../api/patients";
import Header from "../../components/Head"; // Update path to Header component
import PatientModal from "../../components/Patients/PatientModal";
import { PatientTable } from "../../components/Patients/PatientTable";
import TablePagination from "../../components/TablePagination";
import { PatientDto } from "../../types/patient";
import { downloadClientTemplate } from "../../utils";
import BulkUploadModal from "../../components/Patients/BulkPatientUploadModal";
import { SearchByName } from "../../components/SearchByName";

export default function ViewPatientsPage() {
  const [refresh, setRefresh] = useState<boolean>(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState<PatientDto[]>([]); // Placeholder for patient data
  const [nameSearch, setNameSearch] = useState("");
  const [selectedPatient, setSelectedPatient] = useState<PatientDto | null>(
    null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPatients, setTotalPatients] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const fetchAllPatients = async (name: string) => {
    try {
      setLoading(true);
      const resp = await getPatientList({
        page: currentPage,
        size: itemsPerPage,
        search: name,
      });
      if (resp.data) {
        setPatients(resp.data.data);
        setTotalPatients(resp.data.total);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        // should be handled by router. user redirected to login page
        return;
      }
      toast({
        title: "Failed to fetch patient info",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    // Fetch patients from the server
    fetchAllPatients(nameSearch);
    setRefresh(false);
  }, [currentPage, itemsPerPage, refresh]);

  const toast = useToast();
  const handleModalClose = () => setModalOpen(false);

  const [isBulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);

  const handleDownloadTemplate = () => {
    downloadClientTemplate();
  };

  const handleSubmitBulkUpload = async (file: File) => {
    try {
      const response = await bulkCreatePatients(file);
      toast({
        title: "Bulk upload successful",
        description: `${response.data.results.successCount} patients created successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setRefresh(true);
    } catch (error: any) {
      toast({
        title: "Bulk upload failed",
        description: error?.response?.data?.message || "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setBulkUploadModalOpen(false);
  };

  const handleSubmitPatient = async (patientData: PatientDto) => {
    if (selectedPatient) {
      // Update existing patient
      await updatePatient(selectedPatient.id, {
        ...patientData,
      });
      toast({
        title: "Patient updated",
        description: `Patient ${patientData.name} has been updated successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setRefresh(true);
    } else {
      try {
        // Create new patient
        const newPatient = await createPatient({
          ...patientData,
        });
        setPatients([...patients, newPatient.data]);
        toast({
          title: "Created new patient",
          description: `Patient ${newPatient.data.name} has been created successfully`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error: any) {
        toast({
          title: "Failed to create patient",
          description: error?.response?.data?.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    handleModalClose();
  };

  const handleEdit = (patientId: string) => {
    const patientToEdit = patients.find((patient) => patient.id === patientId);
    if (patientToEdit) {
      setSelectedPatient(patientToEdit);
      setModalOpen(true);
    }
  };

  const handleDelete = async (patientId: string) => {
    await deletePatient(patientId);
    setPatients(patients.filter((patient) => patient.id !== patientId));
  };

  return (
    <>
      <Header description="Manage clients" />
      <Container maxW={"9xl"}>
        <Stack>
          <Flex justifyContent={"space-between"}>
            <VStack align={"left"}>
              <Heading fontSize={{ base: "3xl", "2xl": "4xl" }} textTransform="uppercase">Clients</Heading>
            </VStack>
            <Menu>
              <MenuButton as={Button} size={{ base: "md", "2xl": "lg" }} rightIcon={<ChevronDownIcon />}>
                Create Client
              </MenuButton>
              <MenuList>
                <MenuItem
                  py={{ base: 2, "2xl": 3 }}
                  fontSize={{ base: "md", "2xl": "lg" }}
                  onClick={() => {
                    setSelectedPatient(null);
                    setModalOpen(true);
                  }}
                >
                  Create a client
                </MenuItem>
                <MenuItem
                  py={{ base: 2, "2xl": 3 }}
                  fontSize={{ base: "md", "2xl": "lg" }}
                  onClick={() => setBulkUploadModalOpen(true)}
                >
                  Bulk upload from CSV
                </MenuItem>
                <MenuItem
                  py={{ base: 2, "2xl": 3 }}
                  fontSize={{ base: "md", "2xl": "lg" }}
                  onClick={handleDownloadTemplate}
                >
                  Download template CSV for bulk upload
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <SearchByName
            nameSearch={nameSearch}
            placeholder="Search by Name"
            setNameSearch={setNameSearch}
            onSearch={fetchAllPatients}
          />
          <Box>
            <HStack spacing={2}>
              <Text fontSize={{ base: "lg", "2xl": "xl" }}>Filter By:</Text>
              <Tag size={{ base: "md", "2xl": "lg" }} colorScheme="gray" textColor={"gray"}>Staff in Charge</Tag>
              <Tag size={{ base: "md", "2xl": "lg" }} colorScheme="gray" textColor={"gray"}>Tags</Tag>
            </HStack>
          </Box>
          <Box mt={6}>
            <PatientTable
              patients={patients}
              onEdit={handleEdit}
              onDelete={handleDelete}
              loading={loading}
            />
            <TablePagination
              currentPage={currentPage}
              totalRows={totalPatients}
              currentPageRows={patients.length}
              onPageChange={setCurrentPage}
              rowsPerPage={itemsPerPage}
              onChangeRowsPerPage={setItemsPerPage}
            />
          </Box>
        </Stack>
      </Container>

      <PatientModal
        initialData={selectedPatient}
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleSubmitPatient}
      />
      <BulkUploadModal
        isOpen={isBulkUploadModalOpen}
        onClose={() => setBulkUploadModalOpen(false)}
        onSubmit={handleSubmitBulkUpload}
      />
    </>
  );
}
