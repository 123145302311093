import { Checkbox, CheckboxGroup, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { ClientSchemaFieldDto } from "../../../types/organization";

interface CheckboxFieldProps {
  field: ClientSchemaFieldDto;
  initialValue?: string,
  handleInputChange: (key: string, value: string) => void;
}

export const CheckboxField = ({
  field,
  initialValue,
  handleInputChange,
}: CheckboxFieldProps) => {
  const [value, setValue] = useState<string>(initialValue || "false");

  return (
    <CheckboxGroup colorScheme="blue">
      <VStack align="flex-start">
        <Checkbox
          size={{ base: "md", '2xl': "lg" }}
          isChecked={value == "true"}
          onChange={(e) => {
            console.log(value);
            setValue(e.target.checked ? "true" : "false");
            handleInputChange(field.key, e.target.checked ? "true" : "false");
          }}
        >
        </Checkbox>
      </VStack>
    </CheckboxGroup>
  );
};
