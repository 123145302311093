import {
  Box,
  FormControl,
  HStack,
  Input,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  FormTypeField,
} from "../../../types/form";
import { theme } from "../../../constants";
import CheckboxOptions from "./CheckboxOptions";

interface CheckboxFlowFieldProps {
  field: FormTypeField;
  updateField?: (field: FormTypeField) => void;
}

const CheckboxFlowField = ({ field, updateField }: CheckboxFlowFieldProps) => {
  const parseOptions = (options: string) => {
    const optionsArray = options
      .split(";");
    if (options === "" ||optionsArray.length === 0) {
      return {};
    }

    const optionsObject = optionsArray.reduce((acc, option) => {
      acc[option] = option;
      return acc;
    }, {} as Record<string, string>);
    return optionsObject;
  };
  
  return (
    <VStack w="full" align={"left"}>
      <FormControl
        isRequired
        alignItems={"center"}
      >
        <HStack w={"full"}>
          <Input
            size={{ base: "sm", "2xl": "md" }}
            fontSize={{ base: "md", "2xl": "lg" }}
            fontWeight="bold"
            color={`${theme}.800`}
            value={Object.keys(field.options || {}).join(";")}
            onChange={(e) => {
              const newField: FormTypeField = {
                ...field,
                options: parseOptions(e.target.value),
              };
              updateField && updateField(newField);
            }}
            placeholder="Enter options separated by ;"
          />
          <Tooltip shouldWrapChildren label="Checkbox options for the question.">
            <AiOutlineInfoCircle />
          </Tooltip>
        </HStack>
      </FormControl>
      <Box>
        {field.options && (
          <CheckboxOptions
            options={field.options}
          />
        )}
      </Box>
    </VStack>
  );
};

export default CheckboxFlowField;
