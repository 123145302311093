import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Card,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { ChartData } from "chart.js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPatientMetricList } from "../../api/patientMetric";
import { getPatientById } from "../../api/patients";
import Header from "../../components/Head"; // Update path to Header component
import Activity from "../../components/Patients/Activity";
import LineChartComponent from "../../components/Patients/LineChartComponent";
import ClientDetail from "../../components/Patients/ClientDetail";
import { TagPill } from "../../components/Tags/TagPill";
import { ROUTES, theme } from "../../constants";
import { propertyUnits } from "../../constants/properties";
import { PatientDto } from "../../types/patient";
import { Property, TagDto } from "../../types/protocol";
import { PatientMetricDto } from "../../types/form";
import { toTitleCase } from "../../utils";
import { formatDate, formatDateToReadableString } from "../../utils/date";
import { parseISO } from "date-fns";
import { MetricConfiguration } from "../../components/MetricConfiguration";

export default function ViewPatientInfoPage() {
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [patient, setPatient] = useState<PatientDto>();
  const [metricValues, setMetricValues] = useState<PatientMetricDto[][]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const { clientId } = useParams();

  useEffect(() => {
    if (!clientId) {
      return;
    }
    fetchPatientById(clientId);
    getPatientMetrics(clientId);
  }, [clientId]);
  const getPatientMetrics = async (clientId: string) => {
    setLoading(true);
    const metrics = await getPatientMetricList({ patientIds: [clientId] });
    if (!metrics) {
      setLoading(false);
      return
    };

    const groupedMetrics: Record<string, PatientMetricDto[]> = {};
    metrics.forEach((metric) => {
      const timestamp = parseInt(metric.timestamp);
      if (!groupedMetrics[timestamp]) {
        groupedMetrics[timestamp] = [];
      }
      groupedMetrics[timestamp].push(metric);
    });
    setLoading(false);
    setMetricValues(
      Object.entries(groupedMetrics)
        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
        .map(([, value]) => value)
    );
  };

  const fetchPatientById = async (patientId: string): Promise<boolean> => {
    try {
      setLoading(true);
      if (!patientId) {
        setLoading(false);
        return false;
      }
      const resp = await getPatientById(patientId);
      const patient = resp.data;
      if (patient) {
        setPatient({
          ...patient,
          dob:
            typeof patient.dob === "string"
              ? parseISO(patient.dob)
              : patient.dob,
        });
        setLoading(false);
        return true;
      }
    } catch (error) {
      console.error("Error fetching patient", error);
    } finally {
      setLoading(false);
      return false;
    }
  };

  if (loading || !patient) {
    return <div>Loading...</div>;
  }

  const getGraphData = (properties: string[], idx: number): ChartData => {
    if (metricValues.length === 0) return { labels: [], datasets: [] };

    const filtered: PatientMetricDto[][] = [];
    metricValues.forEach((m) => {
      const filteredMetrics = m.filter((d) =>
        properties.includes(d.propertyId)
      );
      if (filteredMetrics.length > 0) {
        filtered.push(filteredMetrics);
      }
    });
    if (filtered.length === 0) return { labels: [], datasets: [] };
    return {
      labels: filtered.map((m) => {
        const date = new Date(parseInt(m[0].timestamp));
        const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits for day
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two digits for month

        return `${day}/${month}`;
      }),
      datasets: properties.map((property, i) => {
        return {
          label: toTitleCase(property),
          data: filtered.map((m) => {
            const metric = m.find((d) => d.propertyId === property);
            return metric?.value ?? null;
          }),
          fill: false,
          borderColor: `rgb(${idx + i * 200}, 99, 132)`,
          tension: 0.1,
        };
      }),
    };
  };

  const getLatestMetric = (property: string) => {
    // iterate reverse to find the latest metric
    for (let i = metricValues.length - 1; i >= 0; i--) {
      const metric = metricValues[i].find((m) => m.propertyId === property);
      if (metric) return metric;
    }
  };

  const bpSysLatest = getLatestMetric(
    Property.BLOOD_PRESSURE_SYSTOLIC.toString()
  );

  const bpDiaLatest = getLatestMetric(
    Property.BLOOD_PRESSURE_DIASTOLIC.toString()
  );

  const handleSearch = (search: string) => {
    if (!search || search === "") {
      setShowSearch(false);
    } else {
      setShowSearch(true);
    }
  };

  const renderTags = (tags: TagDto[]) => {
    if (!tags || tags.length === 0) {
      return (
        <TagPill key={"notags"} tagColor={"grey"} name={"No tags yet"} />
      )
    }
    const tag = tags[0];
    return (
      <Flex flexWrap={"wrap"} justifyContent={"center"} gap={1}>
        {tags.map((tag) => <TagPill key={tag.id} tagColor={tag.tagColor} name={tag.name} />)}
      </Flex>
    );
  };

  return (
    <>
      <Header description="View and manage your clients" />
      <Container maxW={"9xl"}>
        <VStack align={"start"}>
          <Breadcrumb mb={8}>
            <BreadcrumbItem>
              <BreadcrumbLink fontWeight={"light"} href={ROUTES.CLIENTS}>Clients</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink isCurrentPage href={`${ROUTES.CLIENTS}/${patient.id}`}>{patient.name}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <HStack w="full" justify={"space-between"} mb={4}>
            <Heading textTransform={"uppercase"} fontSize={"4xl"}>{patient.name}</Heading>
            <HStack>
              <Box>
                <Text fontWeight={"medium"} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.600`} fontFamily={"heading"}>Last Activity</Text>
                <Text fontWeight={"medium"} fontSize={{ base: "md", "2xl": "lg" }} color={`${theme}.900`}>{formatDateToReadableString(patient.lastActivity!)}</Text>
              </Box>
              <Box maxW="xs">
                {renderTags(patient.tags!)}
              </Box>
            </HStack>
          </HStack>

          <Accordion allowToggle allowMultiple w="full">
            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' py={2}>
                  <Heading fontWeight="medium" color={`${theme}.900`} fontSize={{ base: "lg", md: "xl" }}>Client Details</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <ClientDetail patient={patient} />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' py={2}>
                  <Heading fontWeight="medium" color={`${theme}.900`} fontSize={{ base: "lg", md: "xl" }}>Monitor Vitals</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <MetricConfiguration
                  client={patient}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </VStack>

        <Divider my={4} />
        <Tabs isFitted size={{ base: "md", "2xl": "lg" }}>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Repository</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Card p={4} mt={4}>
                <Text mb={2} fontSize={{ base: "lg", "2xl": "xl" }} color={`${theme}.900`} fontWeight={"bold"}>
                  Health Data Trackers
                </Text>
                {metricValues.length > 0 && (
                  <Flex wrap={"wrap"} justifyContent={"center"}>
                    {bpSysLatest && bpDiaLatest && (
                      <Box m={8} w={400}>
                        <Heading color={`${theme}.600`} fontSize={{ base: "sm", "2xl": "md" }}>
                          {toTitleCase("Blood Pressure")}
                        </Heading>
                        <Text fontSize={{ base: "sm", "2xl": "md" }}>
                          {bpSysLatest?.value ?? "-"}/
                          {bpDiaLatest?.value ?? "-"} mmHg
                        </Text>
                        <Text fontSize={{ base: "sm", "2xl": "md" }} mb={4}>
                          Last recorded:{" "}
                          {formatDate(bpSysLatest?.timestamp ?? "")}
                        </Text>
                        <LineChartComponent
                          data={getGraphData(
                            [
                              Property.BLOOD_PRESSURE_SYSTOLIC,
                              Property.BLOOD_PRESSURE_DIASTOLIC,
                            ],
                            0
                          )}
                        />
                      </Box>
                    )}
                    {Object.keys(Property)
                      .filter(
                        (p) =>
                          !(
                            p === Property.BLOOD_PRESSURE_DIASTOLIC ||
                            p === Property.BLOOD_PRESSURE_SYSTOLIC
                          )
                      )
                      .map((key, idx) => {
                        const metric = getLatestMetric(key);
                        const graphData = getGraphData([key], idx);
                        if (
                          !metric ||
                          (graphData.datasets[0] &&
                            graphData.datasets[0].data.filter((d) => d)
                              .length === 0)
                        ) {
                          return null;
                        }
                        return (
                          <Box m={8} w={400}>
                            <Heading color={`${theme}.600`} fontSize={{ base: "sm", "2xl": "md" }}>
                              {toTitleCase(metric?.propertyId)}
                            </Heading>
                            <Text fontSize={{ base: "sm", "2xl": "md" }}>
                              {metric?.value ?? "-"}{" "}
                              {metric.unit ? metric.unit : ""}
                              {!metric.unit && metric?.propertyId
                                ? propertyUnits[
                                metric.propertyId as keyof typeof Property
                                ]
                                : ""}
                            </Text>
                            <Text fontSize={{ base: "sm", "2xl": "md" }} mb={4}>
                              Last recorded:{" "}
                              {formatDate(metric?.timestamp ?? "")}
                            </Text>
                            <LineChartComponent data={graphData} />
                          </Box>
                        );
                      })}
                  </Flex>
                )}
                {metricValues.length === 0 && <Text>No metrics yet!</Text>}
              </Card>

              {/* <Card p={4} mt={4}>
                <Text mb={2} fontSize={"lg"} fontWeight={"bold"}>
                  Flows
                </Text>
                <SimpleGrid columns={2} spacing={10}>
                  {forms.map((flow) => (
                    <GridItem w="100%" key={flow.id}>
                      <Card
                        _hover={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(
                            `${ROUTES.CLIENTS}/${patient.id}/flow/${flow.id}`
                          )
                        }
                      >
                        <CardHeader>
                          <Tag
                            variant="solid"
                            colorScheme={getStatusColor(flow.status)}
                            maxW={"fit-content"}
                            textTransform={"capitalize"}
                          >
                            {flow.status.toLowerCase()}
                          </Tag>
                          <Text mt={2} fontSize="xl" fontWeight={"bold"}>
                            {flow.name}
                          </Text>
                        </CardHeader>
                        <CardBody mt={-4}>
                          <Text color="gray">
                            {getReadableRRule(flow.schedule)}
                          </Text>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ))}
                </SimpleGrid>

                {forms.length === 0 && (
                  <Text>
                    {patient?.name} currently doesn't have any flows.
                  </Text>
                )}
              </Card> */}
            </TabPanel>
            <TabPanel>
              <Activity
                patient={patient}
                refetchPatient={() => fetchPatientById(clientId!)}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  );
}
