import { PatientActivity } from "../../types/patient";
import { getMonthStr } from "../../utils/date";

export const groupActivitiesByMonth = (
  activities: PatientActivity[]
): PatientActivity[][] => {
  const groups: { [key: string]: PatientActivity[] } = {};

  // Group notes by month
  activities.forEach((activity) => {
    if (activity.date) {
      const date = new Date(activity.date);
      const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;
      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      groups[monthYear].push(activity);
    }
  });

  // Convert grouped notes object to array
  const result: PatientActivity[][] = Object.values(groups);

  return result;
};

export const getGroupDateHeader = (group: PatientActivity[]) => {
  return group.length > 0 && group[0].date
    ? getMonthStr(group[0].date) + " " + new Date(group[0].date).getFullYear()
    : "";
};

export function getFirstName(fullName: string) {
  if (!fullName) {
    return '';
  }
  return fullName.split(' ')[0];
}
