import {
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Property } from "../../types/protocol";
import { FormTypeField } from "../../types/form";
import { checkNumberFieldInvalid } from "../../utils/validators";

interface PropertyFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
}

export const PropertyFormField = ({
  field,
  handleInputChange,
}: PropertyFormFieldProps) => {
  const [value, setValue] = useState<string>("");

  // For BMI only
  const [height, setHeight] = useState<string>("");
  const [weight, setWeight] = useState<string>("");

  const calculateBmi = (weightKg: number, heightM: number): number => {
    // BMI formula: weight (kg) / (height (m) * height (m))
    return Number((weightKg / (heightM * heightM)).toFixed(2));
  };

  useEffect(() => {
    const heightM = parseFloat(height) / 100;
    const weightKg = parseFloat(weight);
    if (isNaN(heightM) || isNaN(weightKg)) {
      return;
    }

    const bmi = calculateBmi(weightKg, heightM);
    handleInputChange(field.key, bmi.toString());
  }, [height, weight]);

  if (field.property === Property.BMI) {
    return (
      <>
        <Text>Height (cm)</Text>
        <NumberInput
          isRequired={field.required}
          isInvalid={checkNumberFieldInvalid(field, value)}
          onChange={(valueString) => {
            setHeight(valueString);
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Text mt={2}>Weight (kg)</Text>
        <NumberInput
          isRequired={field.required}
          isInvalid={checkNumberFieldInvalid(field, value)}
          onChange={(valueString) => {
            setWeight(valueString);
          }}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </>
    );
  }
  return (
    <>
      {(field.numberOptions?.max || field.numberOptions?.min) && (
        <FormHelperText>
          (
          {field.numberOptions?.min !== undefined &&
            `Min: ${field.numberOptions.min}, `}
          {field.numberOptions?.max !== undefined &&
            `Max: ${field.numberOptions.max}`}
          )
        </FormHelperText>
      )}
      <NumberInput
        isRequired={field.required}
        isInvalid={checkNumberFieldInvalid(field, value)}
        onChange={(valueString) => {
          setValue(valueString);
          handleInputChange(field.key, valueString);
        }}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
};

export default PropertyFormField;
