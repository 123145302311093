import { FC, useEffect } from 'react'
import {
    Heading,
    VStack,
    Text,
    Button,
    useToast,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Box,
    AccordionIcon,
    Center,
    Spinner,
    Tabs,
    TabList,
    Tab,
} from '@chakra-ui/react'
import { Logo } from '../../Logo'
import { useState } from 'react'
import { MetricConfWithDetail } from '../../types/clientpass';
import { getClientPassConfig } from '../../api/clientpass';
import { getPatientMetricList } from "../../api/patientMetric";
import { PatientMetricDto } from '../../types/form';
import { useParams } from 'react-router-dom';
import { ROUTES, theme } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { Graph } from '../../components/Graph';
import { TimePeriod } from '../../constants/time';
import { PropertyDetails } from '../../types/metricDetail';

const ViewMetricData: FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { clientId } = useParams();

    const [loading, setLoading] = useState(true);
    const [metricsConf, setMetricsConf] = useState<MetricConfWithDetail[]>();
    const [metricValues, setMetricValues] = useState<PatientMetricDto[][]>([]);
    const [selectedTimePeriodTab, setSelectedTimePeriodTab] = useState<number>(0);

    const timePeriodOptions: Record<TimePeriod, string> = {
        [TimePeriod.PAST_WEEK]: "7 day",
        [TimePeriod.PAST_MONTH]: "Month",
        [TimePeriod.ALL_TIME]: "All time",
    }

    const fetchClientPassConfig = async (id: string) => {
        setLoading(true);
        const resp = await getClientPassConfig(id)
        if (resp.message || !resp.data) {
            toast({
                title: "Failed to fetch vitals",
                description: resp.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            setLoading(false);
            return;
        }
        setMetricsConf(resp.data.metrics);
        setLoading(false);
    }
    const getPatientMetrics = async (clientId: string) => {
        setLoading(true);
        const metrics = await getPatientMetricList({ patientIds: [clientId] });
        if (!metrics) {
            setLoading(false);
            return
        };

        const groupedMetrics: Record<string, PatientMetricDto[]> = {};
        metrics.forEach((metric) => {
            const timestamp = parseInt(metric.timestamp);
            if (!groupedMetrics[timestamp]) {
                groupedMetrics[timestamp] = [];
            }
            groupedMetrics[timestamp].push(metric);
        });
        setLoading(false);
        setMetricValues(
            Object.entries(groupedMetrics) //TODO: move this sort logic to backend
                .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                .map(([, value]) => value)
        );
    };
    useEffect(() => {
        fetchClientPassConfig(clientId as string)
        getPatientMetrics(clientId as string)
    }, [clientId])
    if (loading) {
        return (
            <Center w="full" h="100vh">
                <Spinner />
            </Center>
        )
    }
    return (
        <VStack ml={5} mr={5} mt={10}>
            <Logo />
            <Heading as="h1" size="lg">Trends</Heading>
            <Text>View all your trended vitals here</Text>
            <Tabs
                index={selectedTimePeriodTab}
                onChange={(index) => setSelectedTimePeriodTab(index)}
                variant="line"
                colorScheme="blue"
            >
                <TabList>
                    {Object.entries(timePeriodOptions).map(([, value]) => (
                        <Tab>
                            {value}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
            <Accordion allowToggle allowMultiple w="full" mt={6}>
                {metricsConf?.filter(((m) => { return m.enabled })).map((metric, index) => (
                    <AccordionItem key={index} >
                        <AccordionButton>
                            <Box as='span' flex='1' textAlign='left' py={2}>
                                <Heading fontWeight="medium" color={`${theme}.900`} fontSize={{ base: "lg", md: "xl" }}>
                                    {metric.detail.title}
                                </Heading>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>
                            <Graph
                                metricGroupedByTime={metricValues}
                                properties={getPropertiesIdsToDisplay(metric.detail.properties)}
                                selectedTimePeriod={Object.keys(timePeriodOptions)[selectedTimePeriodTab] as TimePeriod}
                            />
                            <Button
                                onClick={() => { navigate(`${ROUTES.METRIC_FORM}/${metric.detail.id}/${clientId}`) }}
                                w="full"
                                mt={4}
                            >+ Record</Button>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </VStack>
    )
}

function getPropertiesIdsToDisplay(properties: PropertyDetails[]): string[] {
    return properties
        .filter(p => p.showInGraph)
        .map(p => p.propertyId);
}

export default ViewMetricData
