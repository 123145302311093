import {
	VStack,
	Text,
	useToast,
	Button,
} from '@chakra-ui/react';
import { sendRegisterClientMessage, sendResetClientPasscodeMessage } from '../../api/auth';
import { useParams, useSearchParams } from "react-router-dom";
import { SendMsgResp } from '../../types/auth';

export enum LinkType {
	REGISTER = "REGISTER",
	RESET_PASSCODE = "RESET_PASSCODE"
}
export type SendRegitserLinkProps = {
	label: string
	buttonLabel: string,
	linkType: LinkType
}

export const ResendLink = (props: SendRegitserLinkProps) => {
	const toast = useToast();
	const { clientId } = useParams();
	const [searchParams] = useSearchParams();
	const onResendLinkClick = async () => {
		if (!clientId) {
			toast({
				title: "Invalid Client ID",
				description: "Cannot resend link without client ID",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			return
		}
		let resp: SendMsgResp;
		/*
			TODO: fix this

			using searchParams.get("redirectPath") in a component that is not a page is not a good design
			ideally this should be passed in as a prop by the pages using this component
		*/
		switch (props.linkType) {
			case LinkType.REGISTER:
				resp = await sendRegisterClientMessage(
					clientId,
					searchParams.get("redirectPath") as string
				);
				break;
			case LinkType.RESET_PASSCODE:
				resp = await sendResetClientPasscodeMessage(
					clientId,
					searchParams.get("redirectPath") as string
				);
				break;
			default:
				resp = await sendRegisterClientMessage(
					clientId,
					searchParams.get("redirectPath") as string
				);
				break;
		}
		if (resp.error) {
			toast({
				title: "Failed to resend link",
				description: resp.error,
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			return;
		}
		toast({
			title: "Success",
			description: "Registration will be sent to your whatsapp",
			status: "success",
			duration: 3000,
			isClosable: true,
		});
	}
	return (
		<VStack
			spacing={3}
			alignContent={"center"}
			margin={4}
			textAlign={"center"}
		>
			<Text
				fontSize={'md'}
				fontWeight={'normal'}
			>
				{props.label}
			</Text>
			<Button
				onClick={onResendLinkClick}
			>
				{props.buttonLabel}
			</Button>
		</VStack>
	);
}
