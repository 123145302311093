import { Property } from "../types/protocol";

export const propertyUnits: Partial<Record<Property, string>> = {
  [Property.BLOOD_GLUCOSE_FASTING]: "mg/dL",
  [Property.BLOOD_GLUCOSE_RANDOM]: "mg/dL",
  [Property.BLOOD_PRESSURE_SYSTOLIC]: "mmHg",
  [Property.BLOOD_PRESSURE_DIASTOLIC]: "mmHg",
  [Property.HEIGHT]: "cm",
  [Property.WEIGHT]: "kg",
  [Property.EXERCISE_MINUTES_PER_DAY]: "minutes",
  [Property.BMI]: "",
};
