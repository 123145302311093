import { getCommonAuth } from "./axios/commonAuth";
import { PatientFormScheduleResponse, PatientFormSendDataResponse } from "../types/form";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

// Note: this only fetches recurring form sends
export async function getSchedulesByFormId(
  formId: string
): Promise<PatientFormScheduleResponse[]> {
  const axios = getCommonAuth();
  const res = await axios.get<PatientFormScheduleResponse[]>(
    `${API_URL}/form-send/schedule/${formId}`
  );
  return res.data;
}

export async function getFormSendsByFormId(
  formId: string
): Promise<PatientFormSendDataResponse[]> {
  const axios = getCommonAuth();
  const res = await axios.get<PatientFormSendDataResponse[]>(
    `${API_URL}/form-send/data/${formId}`,
  )
  return res.data;
}

export async function deleteFormScheduleById(formSendId: string, clientId: string) {
  const axios = getCommonAuth();
  const res = await axios.delete(
    `${API_URL}/form-send/schedule/${formSendId}/${clientId}`,
  );
  return res.status;
}
