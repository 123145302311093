import {
  getRegistrationOptions,
  verifyRegistration,
} from "../../api/webauthn";
import { startRegistration } from '@simplewebauthn/browser';
import { AuthResp } from "./types";

export const webAuthnRegister = async (registerToken: string): Promise<AuthResp> => {
  try {
    const regOptions = await getRegistrationOptions(registerToken)
    if (regOptions.error || !regOptions.options) {
      return {
        error: {
          title: "Get registration options failed",
          message: regOptions.error as string
        }
      };
    }
    // Pass the options to the authenticator and wait for a response
    let regResp = await startRegistration(regOptions.options);
    if (!regResp) {
      return {
        error: {
          title: "Registration failed",
          message: "Something went wrong"
        }
      }
    }
    const verifyReg = await verifyRegistration(
      { registrationResponse: regResp },
      registerToken,
    )
    if (verifyReg.error) {
      return {
        error: {
          title: "Registration verification failed",
          message: verifyReg.error as string
        }
      }
    }
    return {
      data: verifyReg
    }
  } catch (err: any) {
    return {
      error: {
        title: "Registration failed",
        message: err.message
      }
    };
  }

}
