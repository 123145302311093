import { ReactNode } from "react";
import {
  FaFacebookMessenger,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";

export enum ProviderType {
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
  FB_MESSENGER = "FB_MESSENGER",
}

// Explicitly specify that the second element of the tuple is ReactNode
export interface ProviderConfigValue {
  enabled: boolean,
  icon: ReactNode
}

export const ProviderConfig: Record<ProviderType, ProviderConfigValue> = {
  [ProviderType.WHATSAPP]: {enabled: true, icon: <FaWhatsapp />},
  [ProviderType.TELEGRAM]: {enabled: false, icon: <FaTelegramPlane />},
  [ProviderType.FB_MESSENGER]: {enabled: false, icon: <FaFacebookMessenger />},
};