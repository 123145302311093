import {
    VStack,
    Text,
    Button,
    HStack,
    Icon,
    useToast,
    Link,
} from '@chakra-ui/react';
import { PasscodeInput } from '../../components/PasscodeInput';
import { IoMdFingerPrint, IoIosFingerPrint } from "react-icons/io";
import { useState } from 'react';
import { AuthMethod } from '../../types/auth';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import { LinkType, ResendLink } from '../ResendLink';

export type ClientAuthProps = {
    label: string
    passcode: string
    setPasscode: (value: string) => void
    passcodeButtonCallback: () => void
    biometricsButtonCallback: () => void
    needConfimation: boolean
    availableAuthMethods: AuthMethod[]
    // in case there are no availableAuthMethods
    resendLinkLabel: string
    resendLinkButtonLabel: string
    resendLinkType: LinkType
    // reset passcode
    needResetPasscode: boolean
    resetPasscodeCallback?: () => void

}

const ClientAuth = (props: ClientAuthProps) => {
    const [isVerification, setIsVerification] = useState(false)
    const [verifyPasscode, setVerifyPasscode] = useState('')
    const toast = useToast();
    const passcodeAvailable = props.availableAuthMethods.includes(AuthMethod.PASSCODE)
    const biometricsAvailable = props.availableAuthMethods.includes(AuthMethod.WEBAUTHN) && browserSupportsWebAuthn()

    let label = props.label
    let passcodeButtonLabel = 'Login with passcode'
    let passcode = props.passcode
    let setPasscode = props.setPasscode
    let onPasscodeButtonClick = props.passcodeButtonCallback

    if (props.needConfimation) {
        label = isVerification ? 'Verify Passcode' : props.label
        passcodeButtonLabel = isVerification ? 'Confirm' : 'Next'
        passcode = isVerification ? verifyPasscode : passcode
        setPasscode = isVerification ? setVerifyPasscode : props.setPasscode
        onPasscodeButtonClick = () => {
            if (!isVerification) {
                setIsVerification(true)
                return
            }
            if (verifyPasscode !== props.passcode) {
                toast({
                    title: "Passcodes do not match",
                    description: "Please enter the same passcode",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                setIsVerification(false)
                props.setPasscode('')
                setVerifyPasscode('')
                return
            }
            props.passcodeButtonCallback()
        }
    }

    if (!passcodeAvailable && !biometricsAvailable) {
        return (
            <ResendLink
                label={props.resendLinkLabel}
                buttonLabel={props.resendLinkButtonLabel}
                linkType={props.resendLinkType}
            />
        )
    }
    return (
        <>
            <VStack margin={30} spacing={2}>
                <Text fontSize={'md'}>{label}</Text>
                {passcodeAvailable && <PasscodeInput
                    passcode={passcode}
                    setPasscode={setPasscode}
                />}
                {/* In case only biometrics is available */}
                {!passcodeAvailable && <Icon as={IoIosFingerPrint} fontSize={"12rem"}></Icon>}
            </VStack>
            <HStack>
                {passcodeAvailable && <Button
                    colorScheme={'blue'}
                    onClick={onPasscodeButtonClick}
                >
                    {passcodeButtonLabel}
                </Button>}
                {biometricsAvailable && <Button
                    colorScheme={'blue'}
                    onClick={props.biometricsButtonCallback}
                >
                    <Icon as={IoMdFingerPrint} />
                    {"Scan"}
                </Button>}
            </HStack>
            {props.needResetPasscode && <Link
                color={"blue.700"}
                onClick={props.resetPasscodeCallback}
            >
                {"Forgot passcode?"}
            </Link>}
        </>
    )
}

export {
    ClientAuth
}
