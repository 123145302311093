import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";

type SearchByNameProps = {
  nameSearch: string;
  placeholder: string;
  setNameSearch: (nameSearch: string) => void;
  onSearch: (nameSearch: string) => void;
};
export function SearchByName(props: SearchByNameProps) {
  return (
    <InputGroup maxW={"full"} my={6} size={{ base: "md", "2xl": "lg" }} backgroundColor={"gray.50"}>
      <InputLeftElement
        onClick={() => props.onSearch(props.nameSearch)}
      >
        <SearchIcon color={"gray.500"} />
      </InputLeftElement>
      <Input
        value={props.nameSearch}
        onChange={(e) => props.setNameSearch(e.target.value)}
        placeholder={props.placeholder}
        paddingRight={3}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onSearch(props.nameSearch);
          }
        }}
        fontFamily="heading"
      />
      <InputRightElement
        onClick={(e) => {
          props.setNameSearch("");
          props.onSearch("");
        }}
      >
        <CloseIcon color={"gray.500"} />
      </InputRightElement>
    </InputGroup>
  );
}