import {
    VStack,
    Text,
    Icon,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { registerClient, getClientRegisteredAuthMethods } from '../../api/auth';
import { webAuthnRegister } from "../../utils/webauthn";
import { useClientStore } from "../../store/clientStore";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { ClientAuth } from '../../components/ClientAuth';
import { AuthMethod } from '../../types/auth';
import { LinkType } from '../../components/ResendLink';
import { ROUTES } from '../../constants';
import { clientRedirectAfterAuth } from '../../utils/clientRedirect';
import { Logo } from '../../Logo';

const ClientRegister = () => {
    const [passcode, setPasscode] = useState('');
    const loginHook = useClientStore(state => state.login);
    const toast = useToast();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const { clientId } = useParams();
    let redirectPath = searchParams.get("redirectPath")
    const [availableAuthMethods, setAvailableAuthMethods] = useState([
        AuthMethod.PASSCODE,
        AuthMethod.WEBAUTHN
    ] as AuthMethod[]);

    useEffect(() => {
        const fetchAuthMethods = async () => {
            setLoading(true);
            const availableAuthMethods = await getClientRegisteredAuthMethods(clientId as string);
            if (availableAuthMethods.error) {
                toast({
                    title: "Could not fetch auth methods",
                    description: availableAuthMethods.error,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                return;
            }

            setLoading(false);
            if (availableAuthMethods.authMethods && availableAuthMethods.authMethods.length !== 0) {
                const authMethodsStr = availableAuthMethods.authMethods.join(",");
                if (!redirectPath) {
                    redirectPath = ''
                }
                navigate(`${ROUTES.CLIENTS}/${clientId}${ROUTES.LOGIN}?authMethods=${authMethodsStr}&redirectPath=${redirectPath}`);
            }
        }
        fetchAuthMethods();
    }, [clientId])

    const handleRegisterWithPasscode = async () => {
        const resp = await registerClient(searchParams.get("registerToken") as string, passcode);
        if (resp.error) {
            let errMsg = resp.error
            if (resp.error === "Unauthorized") {
                errMsg = "Registration link has expired";
                /*
                   TODO: fix this
                   
                   We are setting available auth methods to [] to make sure the
                   ClientAuth component shows the ResendLink

                   This is not the best design, ideally the page should handle showing
                   ResendLink component instead of AuthClient.
                */
                setAvailableAuthMethods([]);
            }
            toast({
                title: "Registration Failed",
                description: errMsg,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        loginHook({
            clientId: resp.clientId as string,
            accessToken: resp.accessToken as string
        });
        toast({
            title: "Registration successful",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        clientRedirectAfterAuth(
            navigate,
            clientId as string,
            redirectPath as string
        );
    }
    const handleRegisterWithBiometrics = async () => {
        const resp = await webAuthnRegister(searchParams.get("registerToken") as string);
        if (resp.error) {
            let errMsg = resp.error.message
            if (resp.error.message === "Unauthorized") {
                errMsg = "Registration link has expired";
                /*
                   TODO: fix this
                   
                   We are setting available auth methods to [] to make sure the
                   ClientAuth component shows the ResendLink

                   This is not the best design, ideally the page should handle showing
                   ResendLink component instead of AuthClient.
                */
                setAvailableAuthMethods([]);
            }
            toast({
                title: resp.error.title,
                description: errMsg,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        if (!resp.data) {
            toast({
                title: "Unexpected Error",
                description: "BE response missing auth token",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            return;
        }
        loginHook({
            clientId: resp.data.clientId as string,
            accessToken: resp.data.accessToken as string
        });
        toast({
            title: "Registration with biometrics successful",
            status: "success",
            duration: 3000,
            isClosable: true,
        });
        clientRedirectAfterAuth(
            navigate,
            clientId as string,
            redirectPath as string
        );
    }
    return (
        <VStack
            spacing={3}
            alignContent={"center"}
            margin={4}
            textAlign={"center"}
        >
            <Logo />
            <Text
                fontSize={'3xl'}
                fontWeight={'semibold'}
                width={'3xs'}
            >
                Welcome to Speedback!
            </Text>
            {
                loading ?
                    <Spinner /> :
                    <ClientAuth
                        label={"Register with Passcode or Biometrics"}
                        needConfimation
                        passcode={passcode}
                        setPasscode={setPasscode}
                        passcodeButtonCallback={handleRegisterWithPasscode}
                        biometricsButtonCallback={handleRegisterWithBiometrics}
                        availableAuthMethods={availableAuthMethods}
                        resendLinkLabel='Registration link has expired.'
                        resendLinkButtonLabel='Resend Link'
                        resendLinkType={LinkType.REGISTER}
                        needResetPasscode={false}
                    />
            }
        </VStack>
    );
}

export default ClientRegister;
