import axios from "axios";
import { useClientStore } from "../../store/clientStore";

const axiosAuth = axios.create();
const logout = useClientStore.getState().logout;

// Request interceptor
axiosAuth.interceptors.request.use((config) => {
    const accessToken = useClientStore.getState().accessToken;
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

axiosAuth.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {

        if (error.response?.status === 401) {
            logout();
        }
        throw error;
    }
);

if (process.env.REACT_APP_API_URL?.includes("ngrok")) {
    axiosAuth.interceptors.request.use((config) => {
        config.headers["ngrok-skip-browser-warning"] = "true";
        return config;
    })
}

export default axiosAuth;
