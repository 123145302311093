import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ClientLoginResp } from "./store.types";

export type ClientState = {
	accessToken: string;
	clientId: string | null;
	login: (data: ClientLoginResp) => void;
	logout: () => void;
};

export const useClientStore = create<ClientState>()(
	persist(
		(set) => ({
			accessToken: "",
			clientId: null,
			login: ({ accessToken, clientId }: ClientLoginResp) => {
				set({
					accessToken,
					clientId,
				})
			},
			logout: () => set({ accessToken: "", clientId: null }),
		}),
		{
			name: "client-storage",
		}
	)
);
