import axios from "./axios/clientAuth"
import { GetMetricDetailResp } from "../types/metricDetail"

const API_URL = process.env.REACT_APP_API_URL || "";

export const getMetricDetail = async (id: string): Promise<GetMetricDetailResp> => {
    try {
        let resp = await axios.get<GetMetricDetailResp>(`${API_URL}/metric-detail`, { params: { id } });
        return resp.data;
    } catch (err: any) {
        return {
            message: err.response ? err.response.data.message : err.message,
        }
    }
};
