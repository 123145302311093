import axios from "./axios/noAuth";
import { AxiosHeaders } from "axios";
import {
    OptionsQueryParams,
    RegistrationOptionsResp,
    LoginOptionsResp,
    VerifyRegistrationReq,
    VerifyLoginReq,
    VerificationResp,
} from "../types/webauthn";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getRegistrationOptions = async (registerToken: string): Promise<RegistrationOptionsResp> => {
    try {
        let headers = new AxiosHeaders({
            Authorization: `Bearer ${registerToken}`
        })
        const resp = await axios.get<RegistrationOptionsResp>(
            `${API_URL}/webauthn/registration-options`, { headers }
        );
        return resp.data;
    } catch (err: any) {
        console.log(err)
        return {
            error: err.response ? err.response.data.message : err.message
        }
    }
}

export const getLoginOptions = async (params: OptionsQueryParams): Promise<LoginOptionsResp> => {
    try {
        const resp = await axios.get<LoginOptionsResp>(
            `${API_URL}/webauthn/login-options`, { params }
        );
        return resp.data;
    } catch (err: any) {
        return {
            error: err.response ? err.response.data.message : err.message
        }
    }
}

export const verifyRegistration = async (body: VerifyRegistrationReq, registerToken: string): Promise<VerificationResp> => {
    try {
        let headers = new AxiosHeaders({
            Authorization: `Bearer ${registerToken}`
        })
        const resp = await axios.post<VerificationResp>(
            `${API_URL}/webauthn/verify-registration`,
            body, { headers }
        );
        return resp.data;
    } catch (err: any) {
        return {
            error: err.response ? err.response.data.message : err.message,
        }
    }
}

export const verifyLogin = async (body: VerifyLoginReq): Promise<VerificationResp> => {
    try {
        const resp = await axios.post<VerificationResp>(`${API_URL}/webauthn/verify-login`, body);
        return resp.data;
    } catch (err: any) {
        return {
            error: err.response ? err.response.data.message : err.message,
        }
    }
}
