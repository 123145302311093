import {
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useState } from "react";
import { ClientSchemaFieldDto } from "../../../types/organization";
import { theme } from "../../../constants";
import { checkNumberFieldInvalid } from "../../../utils/validators";

interface NumberFieldProps {
  field: ClientSchemaFieldDto;
  initialValue?: string;
  handleInputChange: (key: string, value: string) => void;
}

export const NumberField = ({
  field,
  initialValue,
  handleInputChange,
}: NumberFieldProps) => {
  const [value, setValue] = useState<string>(initialValue || "");
  return (
    <>
      <NumberInput
        isInvalid={checkNumberFieldInvalid(field, value)}
        value={value}
        onChange={(valueString) => {
          setValue(valueString);
          handleInputChange(field.key, valueString);
        }}
        fontSize={{ base: "md", "2xl": "lg" }}
        fontWeight="medium"
        color={`${theme}.900`}
        variant={"flushed"}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </>
  );
};

export default NumberField;
