import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Text,
  useToast,
  Icon,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getPatientById } from "../../api/patients";
import { getFormById, respondToForm } from "../../api/forms";
import FormField from "../../components/Form/FormField";
import Header from "../../components/Head";
import { ROUTES } from "../../constants";
import { PatientDto } from "../../types/patient";
import { FormDto } from "../../types/form";
import { checkFormValid } from "../../utils/validators";
import { Sun } from 'react-coolicons'
import { Logo } from "../../Logo";
import { ProviderType } from "../../constants/provider";

const Form: React.FC = () => {
  const toast = useToast();
  const [patient, setPatient] = useState<PatientDto>();
  const [form, setForm] = useState<FormDto>();

  const { clientId, formId } = useParams();
  const [queryParams, _] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (clientId) {
        fetchPatientById(clientId);
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to fetch patient details, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [clientId, toast]);

  useEffect(() => {
    try {
      if (formId) {
        fetchFormById(formId);
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description:
          "Unable to fetch form details, please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [formId, toast]);

  const fetchFormById = async (id: string) => {
    const resp = await getFormById(id);
    setForm(resp);
  };

  const fetchPatientById = async (id: string) => {
    const resp = await getPatientById(id);
    if (resp.data) {
      setPatient(resp.data);
    }
  };

  const generateInitialState = () => {
    const initialState: { [key: string]: string } = {};
    form?.fields.forEach((field, idx) => {
      initialState[`q_${idx + 1}`] = "";
    });
    return initialState;
  };

  const [formValues, setFormValues] = useState(generateInitialState()); // Initialize formValues with the generated initial state
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (name: string, value: string) => {
    setFormValues({ ...formValues, [name]: value });

    // Track field interaction
    ReactGA.event({
      category: "Form",
      action: "Field Interacted",
      label: `${formId} - ${name}`,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!patient || !form) {
      return;
    }

    const { success, errorMsg } = checkFormValid(form, formValues);
    if (!success) {
      toast({
        title: "Invalid form.",
        description: errorMsg,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      if (!clientId || !formId) {
        throw new Error("Patient ID or Form ID is missing.");
      }

      const channel = queryParams.get("channel") as ProviderType;
      if (!channel) {
        throw new Error("No channel found!");
      }

      respondToForm({
        patientId: clientId,
        formId: formId,
        rawData: formValues,
        channel: channel,
        sendAcknowledge: true,
      });
      ReactGA.event({
        category: "Form",
        action: "Submit",
        label: "Form Submission",
      });

      setIsSubmitted(true);
    } catch (error: any) {
      toast({
        title: "An error occurred.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!patient || !form) {
    return <Center h="90vh">Loading...</Center>;
  }

  if (isSubmitted) {
    return (
      <Center h="90vh">
        <VStack textAlign="center" p="4">
          <Logo />
          <Heading mb="4">{form.postSubmitMsg?.header ?? ""}</Heading>
          <Text>
            {form.postSubmitMsg?.body ?? "Thank you for submitting!"}
          </Text>
          <Icon
            as={Sun}
            fontSize={'9xl'}
          />
          <Button
            mt={8}
            onClick={() => {
              ReactGA.event({
                category: "Form",
                action: "Post Submit",
                label: "View Data",
              });
              navigate(
                `${ROUTES.CLIENTS}/${patient.id}${ROUTES.FORM_RESPONSE}/${form.id}/view`
              );
            }}
          >
            View Data
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <>
      <Header description="Complete your forms here" />
      <VStack
        spacing={3}
        alignContent={"center"}
        margin={3}
        textAlign={"center"}
      >
        <Logo />
        <Heading fontSize={'2xl'}>Hey {patient?.name} 👋</Heading>
        <Text size="sm" mb={6}>
          {form.message.replace("${name}", patient.name)}
        </Text>
        <Container maxW="full" pe={6} textAlign={"left"}>
          <Box as="form" onSubmit={handleSubmit}>
            {form.fields.map((field, index) => {
              return (
                <FormField
                  key={index}
                  questionNum={index + 1}
                  field={field}
                  handleInputChange={handleInputChange}
                  mb={8}
                />
              );
            })}
            <Button mt="4" type="submit" width={"full"}>
              Submit
            </Button>
          </Box>
        </Container>
      </VStack>

    </>
  );
};

export default Form;
