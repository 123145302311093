import {
    VStack,
    Text,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { PatientDto } from "../../types/patient";
import { getPatientById } from "../../api/patients";
import { getClientPassConfig } from '../../api/clientpass';
import { MetricCard } from '../../components/MetricCard';
import { MetricConfWithDetail } from '../../types/clientpass';
import { getFirstName } from '../../components/Patients/utils';
import { Logo } from '../../Logo';

const ClientPass = () => {
    const [loading, setLoading] = useState(true);
    const [patient, setPatient] = useState<PatientDto>();
    const [metrics, setMetrics] = useState<MetricConfWithDetail[]>();
    const toast = useToast();
    const { clientId } = useParams();

    useEffect(() => {
        const fetchClientById = async (id: string) => {
            try {
                setLoading(true);
                const resp = await getPatientById(id);
                if (resp.data) {
                    setPatient(resp.data);
                    setLoading(false);
                    return;
                }
            } catch (error: any) {
                toast({
                    title: "Failed to fetch patient",
                    description: error?.response?.data?.message || "An error occurred",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                setLoading(false);
            }

        };
        const fetchClientPassConfig = async (id: string) => {
            setLoading(true);
            const resp = await getClientPassConfig(id)
            if (resp.message || !resp.data) {
                toast({
                    title: "Failed to fetch vitals",
                    description: resp.message,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
                setLoading(false);
                return;
            }
            setMetrics(resp.data.metrics);
            setLoading(false);
        }
        if (clientId) {
            fetchClientById(clientId);
            fetchClientPassConfig(clientId);
        }
    }, [clientId])

    return (
        <VStack
            spacing={4}
            alignContent={"center"}
            margin={4}
            textAlign={"center"}
        >
            <Logo />
            {
                loading ?
                    <Spinner /> :
                    <ClientPassBody
                        patient={patient}
                        metrics={metrics}
                        clientId={clientId as string}
                    />
            }

        </VStack>
    );
}

interface ClientPassBodyProps {
    patient?: PatientDto;
    metrics?: MetricConfWithDetail[];
    clientId: string;
}

const ClientPassBody = (props: ClientPassBodyProps) => {
    return (
        <>
            <VStack
                spacing={0}
                mb={4}
            >
                <Text
                    fontSize={'2xl'}
                    fontWeight={'semibold'}
                    width={'3xs'}
                >
                    {`Hello, ${getFirstName(props.patient?.name as string)} 👋`}
                </Text>
                <Text>
                    {"What are you recording today?"}
                </Text>
            </VStack>
            <VStack
                spacing={4}
                alignContent={"center"}
                textAlign={"center"}
            >
                {
                    props.metrics?.map((metric) => (
                        metric.enabled && <MetricCard
                            metricDetail={metric.detail}
                            adminView={false}
                            clientId={props.clientId}
                        />
                    ))
                }
            </VStack>
        </>
    )
}

export default ClientPass;
