import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { useStaffStore } from "../../../store/staffStore";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StaffProtectedRoute = ({ children }: any) => {
    const accessToken = useStaffStore((state) => state.accessToken);

    // TODO: once login done properly uncomment
    if (accessToken === "") {
        // user is not authenticated
        return <Navigate to={ROUTES.LOGIN} />;
    }
    return children;
};
