import { Input } from "@chakra-ui/react";
import { ClientSchemaFieldDto } from "../../../types/organization";
import { theme } from "../../../constants";
import { useState } from "react";

interface TextFieldProps {
  field: ClientSchemaFieldDto;
  initialValue?: string;
  handleInputChange: (key: string, value: string) => void;
}

export const TextField = ({
  field,
  initialValue,
  handleInputChange,
}: TextFieldProps) => {
  const [value, setValue] = useState<string>(initialValue || "");

  return (
    <Input
      type="text"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        handleInputChange(field.key, e.target.value);
      }}
      fontSize={{ base: "md", "2xl": "lg" }}
      fontWeight="medium"
      color={`${theme}.900`}
      variant={"flushed"}
    />
  );
};
