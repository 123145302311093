import {
  getLoginOptions,
  verifyLogin,
} from "../../api/webauthn";
import { startAuthentication } from '@simplewebauthn/browser';
import { AuthResp } from "./types";

export const webAuthnLogin = async (clientId: string): Promise<AuthResp> => {
  
  const loginOptions = await getLoginOptions({ clientId });
  if (loginOptions.error || !loginOptions.options) {
    return {
      error: {
        title: "Get login options failed",
        message: loginOptions.error as string
      }
    };
  }
  let authResp;
  try {
    authResp = await startAuthentication(loginOptions.options);
  } catch (err: any) {
    return {
      error: {
        title: "Authentication failed",
        message: err.message as string
      }
    };
  }
  if (!authResp) {
    return {
      error: {
        title: "Authentication failed",
        message: "Something went wrong"
      }
    }
  }
  const loginVerification = await verifyLogin({
    clientId,
    loginResponse: authResp
  })
  if (loginVerification.error) {
    return {
      error: {
        title: "Registration verification failed",
        message: loginVerification.error as string
      }
    }
  }
  return {
    data: loginVerification
  }
}
