import { AxiosInstance } from 'axios';
import staffAuthAxios from "./staffAuth"
import { useStaffStore } from "../../store/staffStore";
import clientAuthAxios from "./clientAuth"
import { useClientStore } from '../../store/clientStore';
import noAuthAxios from "./noAuth"

export function getCommonAuth(): AxiosInstance {
    if (useStaffStore.getState().accessToken) {
        return staffAuthAxios;
    }
    if (useClientStore.getState().accessToken) {
        return clientAuthAxios;
    }
    return noAuthAxios;
}
