import {
  VStack,
  Text,
  useToast
} from '@chakra-ui/react';
import { useState } from 'react';
import { resetClientPasscode } from '../../api/auth';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { ClientAuth } from '../../components/ClientAuth';
import { AuthMethod } from '../../types/auth';
import { LinkType } from '../../components/ResendLink';
import { clientRedirectAfterAuth } from '../../utils/clientRedirect';
import { Logo } from '../../Logo';


const ClientResetPasscode = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [passcode, setPasscode] = useState('');
  const [searchParams] = useSearchParams();
  const [availableResetMethods, setAvailableResetMethods] = useState([AuthMethod.PASSCODE] as AuthMethod[]);

  const redirectPath = searchParams.get("redirectPath");
  const { clientId } = useParams();

  const handleResetPasscode = async () => {
    const resp = await resetClientPasscode(searchParams.get("resetToken") as string, passcode);
    if (resp.error) {
      let errMsg = resp.error === "Unauthorized" ? "Reset link has expired" : resp.error;
      toast({
        title: "Reset Passcode Failed",
        description: errMsg,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      /*
        TODO: fix this
        
        We are setting available auth methods to [] to make sure the
        ClientAuth component shows the ResendLink

        This is not the best design, ideally the page should handle showing
        ResendLink component instead of AuthClient.
      */
      setAvailableResetMethods([]);
      return;
    }
    toast({
      title: resp.message,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    clientRedirectAfterAuth(
      navigate,
      clientId as string,
      redirectPath as string,
    )
  }
  return (
    <VStack
      spacing={3}
      alignContent={"center"}
      margin={4}
      textAlign={"center"}
    >
      <Logo />
      <Text
        fontSize={'3xl'}
        fontWeight={'semibold'}
        width={'3xs'}
      >
        Welcome to Speedback!
      </Text>
      <ClientAuth
        label={"Reset Passcode"}
        needConfimation
        passcode={passcode}
        setPasscode={setPasscode}
        passcodeButtonCallback={handleResetPasscode}
        biometricsButtonCallback={() => { }}
        availableAuthMethods={availableResetMethods}
        resendLinkLabel='Forgot password link has expired.'
        resendLinkButtonLabel='Resend Link'
        resendLinkType={LinkType.RESET_PASSCODE}
        needResetPasscode={false}
      />
    </VStack>
  );
}

export default ClientResetPasscode;
