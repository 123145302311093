import {
  Flex,
  IconButton,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Skeleton,
  useColorModeValue,
  Box,
  Divider,
  HStack,

} from "@chakra-ui/react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ROUTES, theme } from "../../../constants";
import { PatientDto } from "../../../types/patient";
import { TagDto } from "../../../types/protocol";
import { calculateAge, formatDateToReadableString } from "../../../utils/date";
import { TagPill } from "../../Tags/TagPill";

interface PatientTableProps {
  patients: PatientDto[];
  loading?: boolean;
  onEdit?: (patientId: string) => void;
  onDelete?: (patientId: string) => void;
}

export const PatientTable = ({
  patients,
  loading,
  onEdit,
  onDelete,
}: PatientTableProps) => {
  const navigate = useNavigate();
  const hoverBackground = useColorModeValue(`${theme}.100`, `${theme}.700`);
  const viewPatient = (patientId: string) => {
    navigate(`${ROUTES.CLIENTS}/${patientId}`);
  };

  const renderTags = (tags: TagDto[]) => {
    if (!tags || tags.length === 0) {
      return (
        <TagPill key={"notags"} tagColor={"grey"} name={"No tags yet"} />
      )
    }
    const tag = tags[0];
    return (
      <Flex flexWrap={"wrap"} justifyContent={"center"} gap={1}>
        {tags.map((tag) => <TagPill key={tag.id} tagColor={tag.tagColor} name={tag.name} />)}
      </Flex>
    );
  };
  return (
    <Box border="2px solid" borderRadius={"md"} borderColor={"gray.200"} p={4}>
      <Table variant="unstyled" size={{ base: "md", "2xl": "lg" }} fontSize={{ base: "md", "2xl": "lg" }}>
        <Thead>
          <Tr borderBottom={"2px solid"} borderColor={"gray.200"}>
            <Th textAlign={"center"}>Name</Th>
            <Th textAlign={"center"}>Age</Th>
            <Th textAlign={"center"}>Sex</Th>
            <Th textAlign={"center"}>Phone Number</Th>
            <Th textAlign={"center"}>Last Activity Date</Th>
            <Th textAlign={"center"}>Tags</Th>
            <Th textAlign={"center"}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            patients.map((patient) => (
              <Tr
                key={patient.id}
                onClick={() => viewPatient(patient.id)}
                _hover={{
                  bg: hoverBackground,
                  cursor: "pointer",
                }}
              >
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>{patient.name}</Skeleton>
                </Td>
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>{calculateAge(patient.dob)}</Skeleton>
                </Td>
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>{patient.sex}</Skeleton>
                </Td>
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>{patient.whatsappPhoneNum}</Skeleton>
                </Td>
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>{formatDateToReadableString(patient.lastActivity!)}</Skeleton>
                </Td>
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>{renderTags(patient.tags!)}</Skeleton>
                </Td>
                <Td textAlign={"center"}>
                  <Skeleton isLoaded={!loading}>
                    <HStack>
                      <IconButton
                        aria-label="View patient"
                        variant={"ghost"}
                        onClick={(e) => {
                          e.stopPropagation();
                          viewPatient(patient.id);
                        }}
                        icon={<FaEye />}
                      >
                        Edit
                      </IconButton>
                      {onEdit && (
                        <IconButton
                          aria-label="Edit patient"
                          variant={"ghost"}
                          onClick={(e) => {
                            e.stopPropagation();
                            onEdit(patient.id);
                          }}
                          icon={<FaEdit />}
                        >
                          Edit
                        </IconButton>
                      )}
                      {onDelete && (
                        <IconButton
                          aria-label="Delete patient"
                          variant={"ghost"}
                          onClick={(e) => {
                            e.stopPropagation();
                            onDelete(patient.id);
                          }}
                          icon={<FaTrash />}
                        >
                          Delete
                        </IconButton>
                      )}
                    </HStack>
                  </Skeleton>
                </Td>
              </Tr>
            ))
          }
        </Tbody>
      </Table>
    </Box>
  );
};
