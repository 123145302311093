import { getCommonAuth } from "./axios/commonAuth";
import {
  CountResp,
  FormDataDto,
  FormDataListReq,
  FormDataResp,
} from "../types/form";

// Using React environment variable for API URL
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3000";

async function createFormData(
  dto: FormDataDto
): Promise<FormDataDto> {
  const axios = getCommonAuth();
  const res = await axios.post<FormDataDto>(
    `${API_URL}/formData`,
    dto
  );
  return res.data;
}

async function updateFormData(
  dto: FormDataDto
): Promise<FormDataDto> {
  const axios = getCommonAuth();
  const res = await axios.put<FormDataDto>(
    `${API_URL}/formData`,
    dto
  );
  return res.data;
}

async function getFormDataList(
  req?: FormDataListReq
): Promise<FormDataResp[]> {
  const axios = getCommonAuth();
  const res = await axios.get<FormDataResp[]>(
    `${API_URL}/formData/list`,
    {
      params: req,
    }
  );
  return res.data;
}

async function getFormDataCount(
  formIds: string[]
): Promise<CountResp[] | null> {
  const axios = getCommonAuth();
  const res = await axios.get<CountResp[]>(
    `${API_URL}/formData/count`,
    {
      params: { formIds },
    }
  );
  return res.data;
}

async function deprecateFormDataById(
  formId: string
): Promise<FormDataDto | null> {
  const axios = getCommonAuth();
  const res = await axios.delete<FormDataDto>(
    `${API_URL}/formData/${formId}`
  );
  return res.data;
}

export {
  createFormData,
  deprecateFormDataById,
  getFormDataCount,
  getFormDataList,
  updateFormData,
};
