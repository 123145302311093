import { Checkbox, CheckboxGroup, VStack } from "@chakra-ui/react";
import { FormTypeField } from "../../types/form";
import { useState } from "react";

interface CheckboxFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
}

export const CheckboxFormField = ({
  field,
  handleInputChange,
}: CheckboxFormFieldProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleCheckboxChange = (option: string) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    
    setSelectedOptions(updatedOptions);
    handleInputChange(field.key, updatedOptions.join(';'));
  };

  return (
    <>
      {field.options && Object.keys(field.options).length > 0 && (
        <CheckboxGroup colorScheme="blue">
          <VStack align="flex-start">
            {Object.entries(field.options).map(([value, label]) => (
              <Checkbox
                key={value}
                value={value}
                size={{ base: "md", '2xl': "lg" }}
                onChange={() => handleCheckboxChange(value)}
                isRequired={field.required && selectedOptions.length < 1}
              >
                {label}
              </Checkbox>
            ))}
          </VStack>
        </CheckboxGroup>
      )}
    </>
  );
};
