import { FC } from 'react';
import { PatientMetricDto } from '../../types/form';
import { ChartData, ChartDataset } from "chart.js";
import { toTitleCase } from '../../utils';
import LineChartComponent from "../../components/Patients/LineChartComponent";
import { TimePeriod } from '../../constants/time';
import { getFirstDate } from '../../constants/time';
import { Center, Text } from '@chakra-ui/react';

export type GraphProps = {
    /*
        metric values grouped by recorded time
        each element in this array is an array of PatientMetricDto recorded at the same time

        This component is reusing an exitsing implementation of graph data calculation which requires this struct
        I didnt have time to refactor it but I think we dont need to maintain an array of arrays here.

        I believe in the next developer looking at this. You got this :)
    */
    metricGroupedByTime: PatientMetricDto[][];
    properties: string[]; // list of propertyIds from metricGroupedByTime to display on the graph
    selectedTimePeriod: TimePeriod
}

const Graph: FC<GraphProps> = (props: GraphProps) => {
    const getGraphData = (): ChartData => {
        if (props.metricGroupedByTime.length === 0) return { labels: [], datasets: [] };

        let filtered = filterByPropertyIds(props.metricGroupedByTime, props.properties);
        filtered = filterByTimePeriod(filtered, props.selectedTimePeriod);

        if (filtered.length === 0) return { labels: [], datasets: [] }
        return {
            labels: generateLabels(filtered),
            datasets: generateDataset(filtered, props.properties),
        };
    };
    const data = getGraphData();
    if (data.datasets.length === 0) {
        return (
            <Center>
                <Text>No records yet</Text>
            </Center>
        )
    }
    return (
        <LineChartComponent data={getGraphData()} />
    )
}

function filterByPropertyIds(metrics: PatientMetricDto[][], properties: string[]): PatientMetricDto[][] {
    const filtered: PatientMetricDto[][] = [];
    metrics.forEach((m) => {
        const filteredMetrics = m.filter((d) =>
            properties.includes(d.propertyId)
        );
        if (filteredMetrics.length > 0) {
            filtered.push(filteredMetrics);
        }
    });
    return filtered;
}

function generateLabels(metrics: PatientMetricDto[][]): string[] {
    return metrics.map((m) => {
        const date = new Date(parseInt(m[0].timestamp));
        const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits for day
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two digits for month

        return `${day}/${month}`;
    });
}

function generateDataset(metrics: PatientMetricDto[][], properties: string[]): ChartDataset[] {
    return properties.map((property, i) => {
        return {
            label: toTitleCase(property),
            data: metrics.map((m) => {
                const metric = m.find((d) => d.propertyId === property);
                return metric?.value ?? null;
            }),
            fill: false,
            borderColor: `rgb(${i * 200}, 99, 132)`,
            tension: 0.1,
        };
    })
}

function filterByTimePeriod(metrics: PatientMetricDto[][], timePeriod: TimePeriod): PatientMetricDto[][] {
    const firstDate = getFirstDate(timePeriod);
    return metrics.filter((m) => {
        const date = new Date(parseInt(m[0].timestamp));
        return date >= firstDate;
    });
}

export { Graph }
