import {
    ClientPassConf,
    ClientPassConfResp,
    UpsertClientPassResp,
    SendClientPassMessageReqBody,
    SendClientPassMessageResp
} from "../types/clientpass";
import { getCommonAuth } from "./axios/commonAuth";

const API_URL = process.env.REACT_APP_API_URL || "";

export const getClientPassConfig = async (clientId: string): Promise<ClientPassConfResp> => {
    const axios = getCommonAuth();
    try {
        let resp = await axios.get<ClientPassConfResp>(`${API_URL}/cp-config`, { params: { clientId } });
        return resp.data;
    } catch (err: any) {
        return {
            message: err.response ? err.response.data.message : err.message,
        }
    }
}

export const upsertClientPassConfig = async (clientPassConf: ClientPassConf): Promise<UpsertClientPassResp> => {
    const axios = getCommonAuth();
    try {
        let resp = await axios.post<UpsertClientPassResp>(`${API_URL}/cp-config`, clientPassConf);
        return resp.data;
    } catch (err: any) {
        return {
            message: err.response ? err.response.data.message : err.message,
        }
    }
}

export const sendClientPassMessage = async (reqBody: SendClientPassMessageReqBody): Promise<SendClientPassMessageResp> => {
    const axios = getCommonAuth();
    try {
        let resp = await axios.post<SendClientPassMessageResp>(`${API_URL}/cp-config/send-message`, reqBody);
        return resp.data;
    } catch (err: any) {
        return {
            message: err.response ? err.response.data.message : err.message,
        }
    }
}
