import axios from "axios";
import { useStaffStore } from "../../store/staffStore";
import refreshTokenHelper from "../tokenRefresher";

const axiosAuth = axios.create();
const logout = useStaffStore.getState().logout;
const refreshToken = useStaffStore.getState().refreshToken;

// Request interceptor
axiosAuth.interceptors.request.use((config) => {
    const accessToken = useStaffStore.getState().accessToken;
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

axiosAuth.interceptors.response.use(
    (response) => {
        // Display a success message for POST, PUT, or DELETE methods
        const method = response.config.method?.toUpperCase() || "";
        if (
            ["POST", "PUT", "DELETE", "PATCH"].includes(method) &&
            (response.status === 200 || response.status === 201)
        ) {
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            console.log("Auto Refreshing Token");
            // set as true to prevent infinite loop
            originalRequest._retry = true;
            // Make a call with the refresh cookie to obtain a new token
            const refreshResponse = await refreshTokenHelper();
            if (refreshResponse?.status !== 201 && refreshResponse?.status !== 200) {
                logout();
                throw error;
            }
            refreshToken({ accessToken: refreshResponse?.data.accessToken });
            // Retry the original request with the new token
            return axiosAuth(originalRequest);
        }
        // Throw the error if it's not related to token expiration or refresh
        throw error;
    }
);

if (process.env.REACT_APP_API_URL?.includes("ngrok")) {
    axiosAuth.interceptors.request.use((config) => {
        config.headers["ngrok-skip-browser-warning"] = "true";
        return config;
    })
}

export default axiosAuth;
