import { Textarea } from "@chakra-ui/react";
import { FormTypeField } from "../../types/form";

interface LongTextFormFieldProps {
  field: FormTypeField;
  handleInputChange: (key: string, value: string) => void;
}

export const LongTextFormField = ({
  field,
  handleInputChange,
}: LongTextFormFieldProps) => {
  return (
    <Textarea
      placeholder={"Enter your response..."}
      onChange={(e) => handleInputChange(field.key, e.target.value)}
    />
  );
};
