import { Flex, Image, Text } from "@chakra-ui/react";

export const Logo = () => {
  return (
    <Flex
      direction={"column"}
      w={"full"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Image
        height={"50px"}
        width={"50px"}
        src="/speedback_logo.svg"
      />
    </Flex>
  );
};
