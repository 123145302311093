import { Checkbox, VStack } from "@chakra-ui/react";

interface CheckboxOptionsProps {
  options: Record<string, string>;
}

const CheckboxOptions = ({ options }: CheckboxOptionsProps) => {
  return (
    <>
      {Object.keys(options).length > 0 && (
        <VStack align="flex-start">
          {Object.entries(options).map(([value, label]) => (
            <Checkbox
              key={value}
            >
              {label}
            </Checkbox>
          ))}
        </VStack>
      )}
    </>
  );
};

export default CheckboxOptions;
