export function calculateAge(dateOfBirth: Date): number {
  const currentDate = new Date();
  const dob = new Date(dateOfBirth);

  let age = currentDate.getFullYear() - dob.getFullYear();

  // Check if the birthday has occurred this year
  const currentMonth = currentDate.getMonth();
  const dobMonth = dob.getMonth();
  const currentDay = currentDate.getDate();
  const dobDay = dob.getDate();

  if (
    currentMonth < dobMonth ||
    (currentMonth === dobMonth && currentDay < dobDay)
  ) {
    age--;
  }

  return age;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatDate(inputDate: string): string {
  const date = new Date(parseInt(inputDate));

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const monthName = months[monthIndex];

  return `${day} ${monthName} ${year}`;
}

export function getMonthStr(date: Date): string {
  const monthIndex = new Date(date).getMonth();

  const monthName = months[monthIndex];

  return monthName;
}

export const formatDateToReadableString = (inputDate: Date): string => {
  if (!inputDate) {
    return "";
  }
  const date = new Date(inputDate);

  const day = date.getDate();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hour = date.getHours() % 12 === 0 ? 12 : date.getHours() % 12;
  const minute = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${day} ${month} ${year}, ${hour}${ampm}`;
};

export const convDateTimeToISO = (date: Date): string => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  return (new Date(date.getTime() - tzoffset)).toISOString().slice(0, 16);
}
