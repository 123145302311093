import axios from "axios";

const axiosGlobal = axios.create();

if (process.env.REACT_APP_API_URL?.includes("ngrok")) {
    axiosGlobal.interceptors.request.use((config) => {
        config.headers["ngrok-skip-browser-warning"] = "true";
        return config;
    })
}

export default axiosGlobal;
