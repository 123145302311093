export enum ClientSchemaFieldType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
  DROPDOWN = "DROPDOWN"
}

export type ClientSchemaFieldDto = {
  key: string;
  label: string;
  responseType: ClientSchemaFieldType;
  numberOptions?: {
    min?: number;
    max?: number;
  };
  dropdownOptions?: string[];
}
