import { Text, Card, Icon, VStack, HStack, Switch } from "@chakra-ui/react"
import { ChartBarVertical01 } from 'react-coolicons'
import { MetricDetail } from "../../types/metricDetail"
import { useNavigate } from "react-router-dom"
import { ROUTES } from "../../constants"

export type MetricCardProps = {
    metricDetail: MetricDetail
    adminView: boolean
    enabled?: boolean
    switchCallback?: (metricId: string, enabled: boolean) => void
    clientId?: string
}

export const MetricCard = (props: MetricCardProps) => {
    const navigate = useNavigate()
    const onCardClick = () => {
        if (props.adminView) {
            return
        }
        navigate(`${ROUTES.METRIC_FORM}/${props.metricDetail.id}/${props.clientId}`)
    }
    return (
        <>
            <Card variant={"elevated"} onClick={onCardClick}>
                <VStack
                    minW={"3xs"}
                    m={4}
                    spacing={4}
                    alignItems={"left"}
                >
                    <Icon
                        as={ChartBarVertical01}
                        background={"gray.100"}
                        fontSize={"2xl"}
                        color={"blue.900"}
                    />
                    <HStack justifyContent={"space-between"}>
                        <VStack
                            spacing={0}
                            alignItems={"left"}
                            textAlign={"left"}
                        >
                            <Text fontSize={'xl'} color={"blue.900"}>{props.metricDetail.title}</Text>
                            <Text fontSize={'md'} color={"blue.900"}>{props.metricDetail.unitDescription}</Text>
                        </VStack>
                        {
                            props.adminView && <Switch
                                color={"blue.900"}
                                isChecked={props.enabled}
                                onChange={(e) => props.switchCallback?.(props.metricDetail.id, e.target.checked)}
                            />
                        }
                    </HStack>
                </VStack>
            </Card>
        </>
    )
}
