import {
  Box,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllProtocols } from "../../api/protocol";
import Header from "../../components/Head";
import { ROUTES, theme } from "../../constants";
import { ProtocolDto } from "../../types/protocol";
import { AiOutlineSync } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { SearchByName } from "../../components/SearchByName";

export default function AllProtocolsPage() {
  const navigate = useNavigate();

  // variables needed for loading the right protocols
  const [protocols, setProtocols] = useState<ProtocolDto[]>([]);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchAllProtocols(nameSearch);
  }, []);

  const fetchAllProtocols = async (nameSearch: string) => {
    const resp = await getAllProtocols({
      search: nameSearch
    });
    if (resp.data) {
      setProtocols(resp.data.data);
    }
  };

  return (
    <>
      <Header description="View all protocols" />
      <Container maxW={"9xl"}>
        <VStack w="full" align={"flex-start"} spacing={4}>
          <Heading fontSize={{ base: "3xl", "2xl": "4xl" }} textTransform="uppercase">Protocol</Heading>
          <Text 
            maxW="80%"
            fontSize={{ base: "lg", "2xl": "xl" }}
            fontWeight="normal"
            color={`${theme}.700`}
          >
            Automatically apply clinical protocols to clients
          </Text>

          <Divider />

          <SearchByName
            nameSearch={nameSearch}
            placeholder="Search by Protocol Name, Clinician Name"
            setNameSearch={setNameSearch}
            onSearch={fetchAllProtocols}
          />

          <SimpleGrid
            spacing={4}
            w="full"
            columns={{ base: 1, sm: 2, md: 3, lg: 4, 'xl': 5 }}
            justifyContent={"start"}
            p={1}
          >
            <Card
              key={"create"}
              onClick={() => navigate(`${ROUTES.PROTOCOLS}/new`)}
              cursor={"pointer"}
              variant={"outline"}
              borderColor={`${theme}.900`}
              minW={32}
              minH={52}
            >
              <CardBody alignContent={"center"}>
                <HStack justifyContent={"center"}>
                  <FaPlus size={16} />
                  <Text fontWeight="semibold" fontSize={{ base: "md", "2xl": "lg" }}>Create Protocol</Text>
                </HStack>
              </CardBody>
            </Card>
            {protocols.map((protocol, index) => {
              return (
                <Skeleton isLoaded={!loading}>
                  <Card
                    key={index}
                    onClick={() =>
                      navigate(`${ROUTES.PROTOCOLS}/${protocol.id!}`)
                    }
                    cursor={"pointer"}
                  >
                    <CardBody alignContent={"center"}>
                      <VStack h={"full"} align={"left"} spacing={2}>
                        <Box w="min" bg={`gray.100`} borderRadius={"md"} p={1} mb={2}>
                          <AiOutlineSync size={32} />
                        </Box>
                        <Text 
                          noOfLines={2} 
                          mb={2} 
                          fontSize={{ base: "lg", "2xl": "xl" }} 
                          color={`${theme}.800`} 
                          fontWeight={"medium"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {protocol.name}
                        </Text>
                        <Text 
                          noOfLines={2} 
                          mb={2} 
                          fontSize={{ base: "md", "2xl": "lg" }} 
                          color={`${theme}.800`} 
                          fontWeight={"normal"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                        >
                          {protocol.description}
                        </Text>
                        <Spacer />
                        {protocol.createdBy && (
                          <Text isTruncated fontSize={{ base: "xs", "2xl": "sm" }} color={`${theme}.700`}>
                            Created by {protocol.createdBy}
                          </Text>
                        )}
                      </VStack>
                    </CardBody>
                  </Card>
                </Skeleton>
              );
            })}
          </SimpleGrid>
        </VStack>
      </Container>
    </>
  );
}
