import { HStack, Radio, Text, VStack } from "@chakra-ui/react";
import { OptionsLayout } from "../../../types/form";

interface RadioOptionsProps {
  options: Record<string, string>;
  layout?: OptionsLayout;
  rangeLabels?: {
    low?: string;
    high?: string;
  };
}

const OptionsList = ({ options }: { options: Record<string, string> }) => {
  return (
    <>
      {Object.entries(options).map((o) => (
        <Radio key={o[0]}>{o[1]}</Radio>
      ))}
    </>
  );
};

const RadioOptions = ({ rangeLabels, options, layout }: RadioOptionsProps) => {
  if (layout === OptionsLayout.HORIZONTAL) {
    return (
      <HStack w={"fit-content"} justify={"flex-start"}>
        {rangeLabels?.low && <Text w="100px">{rangeLabels?.low}</Text>}
        <OptionsList options={options} />
        {rangeLabels?.high && <Text w="100px">{rangeLabels?.high}</Text>}
      </HStack>
    );
  }

  return (
    <VStack align={"left"}>
      {rangeLabels?.low && <Text w="100px">{rangeLabels?.low}</Text>}
      <OptionsList options={options} />
      {rangeLabels?.high && <Text w="100px">{rangeLabels?.high}</Text>}
    </VStack>
  );
};

export default RadioOptions;
